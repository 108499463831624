import React, {useEffect, useState} from 'react';
import styles from './Access.module.scss';
import icon from '../../assets/icon.svg';
import googleNormal from '../../assets/google-normal.png';
import googlePressed from '../../assets/google-pressed.png';
import googleNormalRetina from '../../assets/google-normal@2x.png';
import googlePressedRetina from '../../assets/google-pressed@2x.png';
import {FirebaseService} from '../../services/FirebaseService';
import store from '../../store';
import {connect} from 'react-redux';

function Access({user, loading}) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(user == null && !loading);
  }, [user, loading]);

  const toggleLoading = (state) => {
    store.dispatch({type: state ? 'INCREMENT_LOADING' : 'DECREMENT_LOADING'});
  };

  const handleGoogle = () => {
    toggleLoading(true);
    FirebaseService.getInstance()
      .signInWithGoogle()
      .then(() => toggleLoading(false))
      .catch(() => toggleLoading(false));
  };

  if (checked) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            <img
              className={styles.icon}
              src={icon}
              width="50"
              alt="raise.digital"
            />{' '}
            Strava Reporting
          </h1>
          <p>
            Welcome to the raise.digital Strava Reporting Platform. This is a
            demonstration application built entirely on top of facilities
            available through Google Firebase.
          </p>
          <p>Use the button below to log-in using your Google Account.</p>
          <div className={styles.buttons}>
            <button
              onClick={handleGoogle}
              className={`${styles.button} ${styles.google}`}>
              <picture className={styles['google-normal']}>
                <source
                  media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
                  srcSet={googleNormalRetina}
                />
                <img
                  className={styles['button-image']}
                  src={googleNormal}
                  alt="Google"
                />
              </picture>
              <picture className={styles['google-pressed']}>
                <source
                  media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
                  srcSet={googlePressedRetina}
                />
                <img
                  className={styles['button-image']}
                  src={googlePressed}
                  alt="Google"
                />
              </picture>
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loading: state.loading,
  };
};

export default connect(mapStateToProps)(Access);
