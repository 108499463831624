import React, {useEffect, useState} from 'react';
import styles from './App.module.scss';
import {NavLink, Redirect, Switch} from 'react-router-dom';
import routes from '../../routes';
import icon from '../../assets/icon.svg';
import {FirebaseService} from '../../services/FirebaseService';
import store from '../../store';
import Monthly from './Monthly';
import Authorise from './Authorise';
import {connect} from 'react-redux';
import {Route, useLocation} from 'react-router';
import {StravaService} from '../../services/StravaService';

const Routes = ({athlete}) => {
  const location = useLocation();
  return (
    <Switch>
      <Route path={`${routes.authorise}`} exact>
        {athlete ? (
          <Redirect to={{pathname: routes.dashboard}} />
        ) : (
          <Authorise />
        )}
      </Route>
      <Route path={`${routes.dashboard}`} exact>
        {athlete ? (
          <Monthly />
        ) : (
          <Redirect
            to={{pathname: routes.authorise, search: location.search}}
          />
        )}
      </Route>
    </Switch>
  );
};

function App({user, athlete}) {
  const [authorised, setAuthorised] = useState(false);

  useEffect(() => {
    if (user && athlete === false) {
      const dispatchAthlete = (a) =>
        store.dispatch({type: 'SET_ATHLETE', athlete: a});
      toggleLoading(true);
      StravaService.getInstance()
        .getAthlete()
        .then((a) => {
          dispatchAthlete(a);
          toggleLoading(false);
        })
        .catch(() => {
          dispatchAthlete(null);
          toggleLoading(false);
        });
      setAuthorised(athlete != null);
    }
  }, [user, athlete]);

  const toggleLoading = (state) => {
    store.dispatch({type: state ? 'INCREMENT_LOADING' : 'DECREMENT_LOADING'});
  };

  const handleSignout = () => {
    toggleLoading(true);
    FirebaseService.getInstance()
      .signOut()
      .then(() => toggleLoading(false))
      .catch(() => toggleLoading(false));
  };

  let body = null;
  if (athlete !== false) {
    body = <Routes athlete={athlete} />;
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>
          <img
            className={styles.icon}
            src={icon}
            width="30"
            alt="raise.digital"
          />{' '}
          Strava Reporting
        </h1>
        <nav className={styles.nav}>
          <ul>
            <li>
              {authorised ? (
                <NavLink
                  className={styles['nav-link']}
                  to={`${routes.dashboard}`}
                  activeClassName={styles.active}>
                  Monthly Totals
                </NavLink>
              ) : null}
            </li>
            <li>
              <button className={styles['nav-link']} onClick={handleSignout}>
                Sign out
              </button>
            </li>
          </ul>
        </nav>
      </header>
      {body}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    athlete: state.athlete,
  };
};

export default connect(mapStateToProps)(App);
