import React, {useEffect, useState} from 'react';
import styles from './Authorise.module.scss';
import stravaNormal from '../../assets/strava-normal.svg';
import stravaPressed from '../../assets/strava-pressed.svg';
import {StravaService} from '../../services/StravaService';
import store from '../../store';
import {useLocation} from 'react-router';
import queryString from 'query-string';

export default function Authorise({athlete}) {
  const [checked, setChecked] = useState(false);
  const [authorising, setAuthorising] = useState(null);
  const [authoriseUrl, setAuthoriseUrl] = useState(null);
  const [message, setMessage] = useState('');
  const location = useLocation();

  const toggleLoading = (state) => {
    store.dispatch({type: state ? 'INCREMENT_LOADING' : 'DECREMENT_LOADING'});
  };

  useEffect(() => {
    setChecked(athlete !== false);
  }, [athlete]);

  useEffect(() => {
    if (authorising === false) {
      StravaService.getInstance()
        .getAuthoriseUrl()
        .then((url) => {
          setAuthoriseUrl(url);
        })
        .catch((error) => {
          setAuthoriseUrl(null);
        });
    }
  }, [authorising]);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query.error) {
      if (query.error === 'access_denied') {
        setMessage(
          "Sorry we require access to your account as without we can't create reports. We only request the minimum level of access we need.",
        );
      } else {
        setMessage('Sorry there has been a problem, please try again.');
      }
      setAuthorising(false);
    }
    if (query.code) {
      if (query.scope === 'read,activity:read') {
        setAuthorising(true);
        toggleLoading(true);
        StravaService.getInstance()
          .authorise(query.code)
          .then(() => {
            setAuthorising(false);
            toggleLoading(false);
          })
          .catch(() => toggleLoading(false));
      } else {
        setMessage(
          "Sorry you didn't grant all the access we require to create reports. We only request the minimum level of access we need.",
        );
        setAuthorising(false);
      }
    } else {
      setAuthorising(false);
    }
  }, [location.search]);

  if (checked) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Time to&nbsp;<span>Connect</span>
          </h1>
          {message ? (
            <p className={styles.message}>{message}</p>
          ) : (
            <p>
              Thank you for logging in, we now need to access your Strava data.
              Click the button below to grant us access to read from your
              account.
            </p>
          )}
          <div className={styles.buttons}>
            <a
              href={authoriseUrl}
              className={`${styles.button} ${
                authoriseUrl ? null : `${styles.unavailable}`
              }`}>
              <img
                className={styles.normal}
                src={stravaNormal}
                alt="Connect with Strava"
              />
              <img
                className={styles.pressed}
                src={stravaPressed}
                alt="Connect with Strava"
              />
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
