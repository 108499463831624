import React from 'react';
import styles from './ToggleSwitch.module.scss';

export default function StravaProfile({
  leftLabel,
  rightLabel,
  selected,
  onToggle,
}) {
  const handleToggle = () => {
    onToggle(!selected);
  };

  return (
    <div className={styles.container}>
      <span />
      <p>{leftLabel}</p>
      <button
        className={`${styles.switch} ${selected ? styles.selected : null}`}
        onClick={handleToggle}>
        <span className={styles.indicator} />
      </button>
      <p>{rightLabel}</p>
    </div>
  );
}
