import React from 'react';
import moment from 'moment';
import styles from './StravaProfile.module.scss';

export default function StravaProfile({athlete}) {
  return (
    <div className={styles.profile}>
      <a
        href={`https://www.strava.com/athletes/${athlete.id}`}
        target="_blank"
        rel="noopener noreferrer">
        <img
          src={athlete.profile}
          alt={`${athlete.firstname} ${athlete.lastname}`}
        />
      </a>
      <h3 className={styles.name}>
        {athlete.firstname} {athlete.lastname}
      </h3>
      <p className={styles.location}>
        {athlete.city}, {athlete.state}
      </p>
      <p className={styles.joined}>
        Strava member since: {moment(athlete.created_at).format('DD/MM/YYYY')}
      </p>
    </div>
  );
}
