import {createStore} from 'redux';

function user(state = false, action) {
  if (action.type === 'SET_USER') {
    return action.user;
  } else {
    return state;
  }
}

function loading(state = 0, action) {
  if (action.type === 'INCREMENT_LOADING') {
    return state + 1;
  } else if (action.type === 'DECREMENT_LOADING') {
    return state - 1;
  } else {
    return state;
  }
}

function athlete(state = false, action) {
  if (action.type === 'SET_ATHLETE') {
    return action.athlete;
  } else {
    return state;
  }
}

function app(state = {}, action) {
  return {
    user: user(state.user, action),
    loading: loading(state.loading, action),
    athlete: athlete(state.athlete, action),
  };
}

const store = createStore(app);

export default store;
