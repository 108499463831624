import React, {useEffect} from 'react';
import styles from './App.module.scss';
import {default as AppModule} from './app/App';
import {default as AccessModule} from './access/Access';
import {BrowserRouter as Router, Redirect, Switch} from 'react-router-dom';
import * as moment from 'moment';
import raise from '../assets/developed-by.svg';
import firebase from '../assets/firebase.svg';
import strava from '../assets/strava.svg';
import {FirebaseService} from '../services/FirebaseService';
import store from '../store';
import LinearProgress from '@material-ui/core/LinearProgress';
import {createMuiTheme} from '@material-ui/core';
import {connect} from 'react-redux';
import {Route, useLocation} from 'react-router';
import routes from '../routes';

createMuiTheme({
  palette: {
    primary: {
      main: '#3a277b',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f45d01',
      contrastText: '#fff',
    },
  },
});

const Routes = ({user}) => {
  const location = useLocation();
  return (
    <Switch>
      <Route path="/auth">
        {user ? (
          <Redirect
            to={{pathname: routes.dashboard, search: location.search}}
          />
        ) : (
          <AccessModule />
        )}
      </Route>
      <Route path="/">
        {user ? (
          <AppModule />
        ) : (
          <Redirect to={{pathname: routes.login, search: location.search}} />
        )}
      </Route>
    </Switch>
  );
};

function App({user, loading, token}) {
  const toggleLoading = (state) => {
    store.dispatch({type: state ? 'INCREMENT_LOADING' : 'DECREMENT_LOADING'});
  };

  useEffect(() => {
    const firebaseSubscription = FirebaseService.getInstance().subscribeToAuthChanges();

    toggleLoading(true);
    FirebaseService.getInstance()
      .getSignInResult()
      .then((result) => toggleLoading(false))
      .catch(() => toggleLoading(false));

    return () => {
      firebaseSubscription();
    };
  }, []);

  let body = null;
  if (user !== false) {
    body = (
      <Router>
        <Routes user={user} loading={loading} token={token} />
      </Router>
    );
  }

  let loadingBar = null;
  if (loading) {
    loadingBar = (
      <LinearProgress
        style={{
          left: 0,
          position: 'absolute',
          right: 0,
          top: -4,
        }}
      />
    );
  }

  return (
    <div className={`${styles.container} ${loading ? styles.loading : null}`}>
      {body}
      <footer className={styles.footer}>
        {loadingBar}
        <p className={styles.copyright}>
          Copyright {moment().format('YYYY')} &copy;{' '}
          <a
            href="https://raise.digital"
            target="_blank"
            rel="noopener noreferrer">
            <span>raise.</span>digital
          </a>
        </p>
        <img
          className={styles.logo}
          src={raise}
          width="150"
          alt="Developed by raise.digital"
        />
        <img
          className={`${styles.logo} ${styles.strava}`}
          src={strava}
          width="120"
          alt="Powered by Strava"
        />
        <img
          className={styles.logo}
          src={firebase}
          width="150"
          alt="Built with Firebase"
        />
      </footer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loading: state.loading,
    token: state.athlete,
  };
};

export default connect(mapStateToProps)(App);
