import React, {useEffect, useState} from 'react';
import styles from './YearSummary.module.scss';
import {FirebaseService} from '../../../services/FirebaseService';
import {StravaService} from '../../../services/StravaService';
import store from '../../../store';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBicycle,
  faHiking,
  faRunning,
  faSwimmer,
} from '@fortawesome/free-solid-svg-icons';
import {default as convert} from 'convert-units';
import ToggleSwitch from './ToggleSwitch';

export default function YearSummary({year, athlete, loading}) {
  const [summary, setSummary] = useState(null);
  const [totals, setTotals] = useState(null);
  const [kilometres, setKilometres] = useState(false);

  const toggleLoading = (state) => {
    store.dispatch({type: state ? 'INCREMENT_LOADING' : 'DECREMENT_LOADING'});
  };

  const displayDistance = (meters) => {
    return (
      Math.round(
        convert(meters)
          .from('m')
          .to(kilometres ? 'km' : 'mi') * 10,
      ) / 10
    );
  };

  useEffect(() => {
    FirebaseService.getInstance()
      .getYear(year)
      .then((existing) => {
        if (!existing) {
          toggleLoading(true);
          StravaService.getInstance()
            .loadYear(year)
            .then(() => toggleLoading(false))
            .catch(() => toggleLoading(false));
        }
      })
      .catch(() => {});
  }, [year, athlete]);

  useEffect(() => {
    let subscription = null;
    if (year) {
      FirebaseService.getInstance().subscribeToYear(year, (value) => {
        if (value) {
          const updatedTotals = {run: 0, ride: 0, walk: 0, swim: 0};
          const updated = [];
          Object.values(value).forEach((month) => {
            updated.push(month);
            updatedTotals.run += month.run;
            updatedTotals.ride += month.ride;
            updatedTotals.walk += month.walk;
            updatedTotals.swim += month.swim;
          });
          setSummary(updated.sort((m1, m2) => (m1.key > m2.key ? 1 : -1)));
          setTotals(updatedTotals);
        }
      });
    }
    return () => {
      if (subscription) {
        subscription();
      }
    };
  }, [year]);

  useEffect(() => {
    if (loading) {
      const months = [];
      for (let month = 1; month <= 12; month++) {
        months.push({
          key: month,
          date: moment()
            .set({year: 1970})
            .startOf('year')
            .add(month - 1, 'months')
            .toDate(),
          run: null,
          ride: null,
          walk: null,
          swim: null,
        });
      }
      setSummary(months);
      setTotals({run: null, ride: null, walk: null, swim: null});
    }
  }, [loading]);

  const unit = kilometres ? 'km' : 'mi';

  let yearTotals;
  if (totals) {
    yearTotals = (
      <div className={styles.totals}>
        <h3>Totals:</h3>
        <p>
          <FontAwesomeIcon className={styles.icon} icon={faRunning} size="2x" />{' '}
          <span className={styles.distance}>
            {totals.run === null ? '-' : displayDistance(totals.run)}
          </span>
          <span className={styles.unit}>{unit}</span>
        </p>
        <p>
          <FontAwesomeIcon className={styles.icon} icon={faBicycle} size="2x" />{' '}
          <span className={styles.distance}>
            {totals.ride === null ? '-' : displayDistance(totals.ride)}
          </span>
          <span className={styles.unit}>{unit}</span>
        </p>
        <p>
          <FontAwesomeIcon className={styles.icon} icon={faSwimmer} size="2x" />{' '}
          <span className={styles.distance}>
            {totals.swim === null ? '-' : displayDistance(totals.swim)}
          </span>
          <span className={styles.unit}>{unit}</span>
        </p>
        <p>
          <FontAwesomeIcon className={styles.icon} icon={faHiking} size="2x" />{' '}
          <span className={styles.distance}>
            {totals.walk === null ? '-' : displayDistance(totals.walk)}
          </span>
          <span className={styles.unit}>{unit}</span>
        </p>
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${loading ? styles.loading : null}`}>
      {summary == null
        ? null
        : summary.map((month, i) => (
            <div
              className={`${styles.month} ${
                moment(month.date).diff(moment().startOf('month'), 'days') === 0
                  ? styles.current
                  : null
              } ${
                moment(month.date).diff(moment().startOf('month'), 'days') > 0
                  ? styles.future
                  : null
              }`}
              key={`${i}-${year}`}>
              <h4>{moment(month.date).format('MMM')}</h4>
              <p>
                <FontAwesomeIcon className={styles.icon} icon={faRunning} />{' '}
                <span className={styles.distance}>
                  {month.run === null ? '-' : displayDistance(month.run)}
                </span>
                <span className={styles.unit}>{unit}</span>
              </p>
              <p>
                <FontAwesomeIcon className={styles.icon} icon={faBicycle} />{' '}
                <span className={styles.distance}>
                  {month.ride === null ? '-' : displayDistance(month.ride)}
                </span>
                <span className={styles.unit}>{unit}</span>
              </p>
              <p>
                <FontAwesomeIcon className={styles.icon} icon={faSwimmer} />{' '}
                <span className={styles.distance}>
                  {month.swim === null ? '-' : displayDistance(month.swim)}
                </span>
                <span className={styles.unit}>{unit}</span>
              </p>
              <p>
                <FontAwesomeIcon className={styles.icon} icon={faHiking} />{' '}
                <span className={styles.distance}>
                  {month.walk === null ? '-' : displayDistance(month.walk)}
                </span>
                <span className={styles.unit}>{unit}</span>
              </p>
            </div>
          ))}
      {yearTotals}
      <div className={`${styles.controls} ${loading ? styles.loading : null}`}>
        <ToggleSwitch
          leftLabel="miles"
          rightLabel="kilometres"
          selected={kilometres}
          onToggle={setKilometres}
        />
      </div>
    </div>
  );
}
