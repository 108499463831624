import React, {useState} from 'react';
import {connect} from 'react-redux';
import styles from './Monthly.module.scss';
import StravaProfile from './components/StravaProfile';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import YearSummary from './components/YearSummary';

function Monthly({athlete, loading}) {
  const [year, setYear] = useState(moment().year());

  const handlePrevious = () => {
    const previous = year - 1;
    if (previous >= 2000) {
      setYear(previous);
    }
  };

  const handleNext = () => {
    const next = year + 1;
    if (next <= moment().year()) {
      setYear(next);
    }
  };

  return (
    <div className={styles.container}>
      <StravaProfile athlete={athlete} />
      <div className={styles.report}>
        <nav className={styles.nav}>
          <button onClick={handlePrevious}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <h3>{year}</h3>
          <button onClick={handleNext}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </nav>
        <YearSummary athlete={athlete} loading={loading} year={year} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    athlete: state.athlete,
    loading: state.loading,
  };
};

export default connect(mapStateToProps)(Monthly);
