import firebase from '../firebase';
import store from '../store';

export class FirebaseService {
  static getInstance() {
    if (!FirebaseService.instance) {
      FirebaseService.instance = new FirebaseService();
    }
    return FirebaseService.instance;
  }

  currentUser() {
    return firebase.auth().currentUser;
  }

  async signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithRedirect(provider);
  }

  async getSignInResult() {
    return firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.user) {
          store.dispatch({type: 'SET_USER', user: result.user});
        }
        return result.user;
      })
      .catch(() => {});
  }

  subscribeToAuthChanges() {
    return firebase.auth().onAuthStateChanged((user) => {
      const storeUser = store.getState().user;
      if (storeUser === false || (user != null && storeUser.uid !== user.uid)) {
        store.dispatch({type: 'SET_USER', user});
      }
    });
  }

  async signOut() {
    return firebase
      .auth()
      .signOut()
      .then(() => store.dispatch({type: 'SET_USER', user: null}));
  }

  async getYear(year) {
    const user = store.getState().user;

    return firebase
      .firestore()
      .doc(`/users/${user.uid}/years/${year}`)
      .get()
      .then((doc) => (doc ? doc.data() : null));
  }

  subscribeToYear(year, action) {
    return firebase
      .firestore()
      .doc(`/users/${store.getState().user.uid}/years/${year}`)
      .onSnapshot((doc) => {
        action(doc ? doc.data() : null);
      });
  }
}
