import firebase from '../firebase';
import store from '../store';

export class StravaService {
  static getInstance() {
    if (!StravaService.instance) {
      StravaService.instance = new StravaService();
    }
    return StravaService.instance;
  }

  constructor() {
    this.functions = firebase.app().functions('europe-west2');
  }

  async getAuthoriseUrl() {
    const getStravaAuth = this.functions.httpsCallable('getStravaAuth');
    return getStravaAuth().then(this.handleResponse);
  }

  async authorise(code) {
    const authoriseStravaUser = this.functions.httpsCallable(
      'authoriseStravaUser',
    );
    return authoriseStravaUser({code}).then((response) => {
      if (response.data) {
        store.dispatch({type: 'SET_ATHLETE', athlete: response.data});
      }
      return response.data;
    });
  }

  async getAthlete() {
    const getStravaUser = this.functions.httpsCallable('getStravaUser');
    return getStravaUser().then(this.handleResponse);
  }

  async loadYear(year) {
    const getStravaYear = this.functions.httpsCallable('getStravaYear');
    return getStravaYear({year}).then(this.handleResponse);
  }

  handleResponse(response) {
    if (!response.data || response.data.status === 'error') {
      return Promise.reject();
    } else {
      return response.data;
    }
  }
}
