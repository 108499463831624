export default {
  // Access
  login: '/auth/login',

  // Authorise
  authorise: '/authorise',

  // App
  dashboard: '/',
};
