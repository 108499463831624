import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
const config = {
  apiKey: 'AIzaSyCF6QvysHJN8tZX2orimU39JWCatqG1V_A',
  authDomain: 'strava-reporting.firebaseapp.com',
  databaseURL: 'https://strava-reporting.firebaseio.com',
  projectId: 'strava-reporting',
  storageBucket: 'strava-reporting.appspot.com',
  messagingSenderId: '865247508094',
  appId: '1:865247508094:web:45f3fe9f58e60ce9c44a2f',
  measurementId: 'G-1YRNH5LM2Q',
};
firebase.initializeApp(config);

if (process.env.NODE_ENV === 'development') {
  firebase
    .app()
    .functions('europe-west2')
    .useFunctionsEmulator('http://localhost:5001');
  firebase.app().firestore().settings({host: 'localhost:8080', ssl: false});
} else {
  firebase.app().functions('europe-west2');
}
export default firebase;
